import Immutable from 'immutable';

const defaultLabels = Immutable.fromJS({
    "journey_intro_hello": "Hello",
    "journey_get_started_with": "Get started with",
    "duration_label": "Duration",
    "study_label": "Study",
    "duration_unit_min": "Mins",
    "duration_unit_hour": "Hour",
    "duration_unit_day": "Day",
    "duration_unit_week": "Week",
    "duration_unit_month": "Month",
    "duration_unit_year": "Year",
    "journey_study": "10 hrs/week",
    "journey_video_label": "Watch Video",
    "accept_invitation_label": "Get Started",
    "journey_terms_and_condition": "By accepting you agree to our privacy policy and T&C",
    "journey_benifits_and_insights_label": "Benefits and Insights",
    "journey_message_from_label": "Message from COO",
    "journey_prereq_skill_label": "Pre-requisite and Skills Covered",
    "journey_index_label": "Index",
    "journey_intro_why_label": "Why take this program?",
    "journey_prereq_label": "Pre-requisite",
    "journey_skill_covered_label": "Skills Covered",
    "phases_label": "Phases",
    "journey_what_learn": "What you will learn",
    "intelligent_performance_label": "Intelligent performance-based content recommendations",
    "footer_learn_label": "Developing Future Ready Organizations and <br/> Leaders through Experiential Learning",
    "footer_learn_desc_label": "Now you can achieve your goals on the move.",
    "next_activity_label": "Your Next Activity",
    "continue_label": "Continue",
    "need_attention_label": "Need Your Attention",
    "due_by_label": "Due By",
    "expires_on_label": "Expires on",
    "view_stats_label": "View more stats",
    "analytics_label": "Analytics",
    "restriction_label": "There might be certain restrictions on streaming data and installing programs. Please check your firewall settings.",
    "completed_label": "completed",
    "due_date_label": "Due Date",
    "unlock_on_label": "Unlocks on",
    "about_journey_label": "ABOUT COURSE",
    "view_profile_label": "View Profile",
    "settings_label": "Settings",
    "help_label": "Help",
    "signout_label": "Signout",
    "opens_fullscreen_label": "Opens in a fullscreen",
    "instructions_label": "Instructions",
    "next_label": "Next",
    "start_label": "Start",
    "help_video_label": "Help Video",
    "try_demo_label": "Try Demo",
    "competencies_covered_label": "Competencies Covered:",
    "faq_label": "FAQs:",
    "good_job_label": "Good job!",
    "you_got_label": "You got",
    "show_details_label": "Show Details",
    "assessment_summary_label": "Summary of your overall performance",
    "you_are_label": "You are",
    "share_with_label": "Share with",
    "high_score_label": "Your High Scores",
    "low_score_label": "Your Low Scores",
    "novice_label": "NOVICE",
    "emerging_label": "EMERGING",
    "competent_label": "COMPETENT",
    "proficient_label": "PROFICIENT",
    "role_model_label": "ROLE MODEL",
    "plan_label": "Plan",
    "recommendations_label": "Recommendations",
    "show_more_label": "SHOW MORE",
    "view_more_label": "View More",
    "view_less_label": "View Less",
    "module_locked_label": "Locked",
    "count_modules_completed_label": "%{completed_count}/%{total_count} sections completed",
    "due_on_label": "Due on %{date}",
    "count_items_completed_label": "%{completed_count}/%{total_count} items completed",
    "page_label": "Page",
    "video_label": "Video",
    "presentation_label": "Presentation",
    "pdf_label": "PDF",
    "document_label": "Document",
    "scom_content_label": "Content",
    "quiz_label": "Quiz",
    "assignment_label": "Assignment",
    "simulation_label": "Simulation",
    "alp_label": "ALP",
    "classroom_session_label": "Classroom Session",
    "web_session_label": "Web Session",
    "external_url_label": "External URL",
    "filter_label": "Filter",
    "reset_filter_label": "Reset",
    "recommended_label": "Recommended for you",
    "additonal_information_label": "Additional Information",
    "topics_cover_label": "Topics Covered",
    "attempts left_label": "%{count} attempts left",
    "questions_count_label": "%{count} Questions",
    "take_quiz_label": "Take Quiz",
    "questions_label": "Questions",
    "retake_quiz_label": "Re-take Quiz",
    "thanks_for_feedback_label": "Thanks for your valuable feedback",
    "feedback_question_label": "How did you like this content?",
    "restart_label": "Restart",
    "bites_label": "Bites",
    "date_and_time_label": "Date & Time",
    "classroom_resources_label": "Resources",
    "classroom_facilitator_label": "Facilitator",
    "add_to_calendar_label": "Add to Calendar",
    "join_session_label": "Join Session",
    "classroom_venue_label": "Venue",
    "assignment_submission_upload_file_label": "Upload File",
    "assignment_submission_share_link_label": "Share Link",
    "assignment_submission_write_it_label": "Write It",
    "submitted_on_label": "Submitted on",
    "fullscreen_label": "Full Screen",
    "leave_fullscreen_label": "Leave Full Screen",
    "exit_label": "Exit",
    "report_issue_label": "Report Issue",
    "performance_label": "Performance",
    "home_nav_label": "Back to Home",
    "activity_label": "Activity",
    "measure_competency_score_label": "This is a summary of your overall performance on the comeptencies across all assessments",
    "no_module_items_label": "There are no module items assigned to this module",
    "details_heading_label": "Details",
    "recommended_for_you_label": "Recommended for you",
    "points_label": "points",
    "launch_simulation_label": "Launch Simulation",
    "launch_alp_label": "Launch ALP",
    "launch_content_label": "Launch Content",
    "launching_label": "Launching...",
    "add_note_label": "Add a Note",
    "join_live_stream_label": "Join Live Stream",
    "logout_label": "Sign Out",
    "journey_overview_label": "Overview",
    "journey_welcome_banner_text": "Welcome to JOURNEY_NAME",
    "journey_welcome_banner_description": "You must first determine your passion. I mean other than making the money! What is your hobby? What do you know how to do REALLY well?",
    "contents_label": "Contents",
    "home_label": "Home",
    "results_label": "Results",
    "phase_completion_message": "Congratulations, you have completed PHASE_NAME phase.",
    "competency_range_greeting": "You're COMPETENCY_RANGE_NAME",
    "exit_confirmation_message": "Are you sure you want to exit?",
    "summary_label": "Summary",
    "view_activity_label": "View Activity",
    "view_performance_label": "View Performance",
    "report_label": "Report",
    "access_report_label": "Access your report here",
    "competency_score_message": "You have an average score of AVERAGE_SCORE on SCORE_SCALE based on your assessments",
    "on_label": "On",
    "score_snapshot_label": "Score Snapshot",
    "detailed_analysis_label": "Detailed Analysis",
    "show_detailed_analysis": "Show Detailed Analysis",
    "hide_detailed_analysis": "Hide Detailed Analysis",
    "detailed_analysis_defination": "This is the analysis of your performance on the competencies & values across all tools",
    "assessed_criteria_label": "Want to know more on what we assessed?",
    "download_report": "Download Report",
    "score_descriptors_label": "SCORE DESCRIPTORS",
    "measured_with_label": "MEASURED WITH",
    "behaviours_label": "BEHAVIOURS",
    "score_label": "Score",
    "whats_next_label": "what is next?",
    "personal_assessment_plan_label": "Your Personal assessment plan",
    "personal_development_plan_label": "Your personal development plan",
    "first_assessment_label": "Your First Assessment",
    "next_assessment_label": "Your Next Assessment",
    "first_course_item_label": "Your First Item",
    "next_course_item_label": "Your Next Item",
    "locked_label": "Locked",
    "expired_label": "Expired",
    "not_started_label": "Not started",
    "in_progress_label": "In progress",
    "cancel_label": "Cancel",
    "exit_confirm_label": "Are you sure you want to exit?",
    "help_image": "Help Image",
    "mobile_close_label": "CLOSE",
    "mobile_detailed_analysis_label": "Detailed Analysis",
    "mobile_assessment_reports_label": "Assessment Reports",
    "mobile_profile_summary_label": "Profile Summary",
    "mobile_top_competencies_label": "Top Competencies",
    "mobile_score_snapshot": "Score Snapshot",
    "mobile_score_snapshot_des": "This is a summary of your overall performance across all assessments",
    "mobile_areas_of_improvement": "Areas of Improvement",
    "mobile_areas_of_growth": "Areas of Growth(Desirable Range)",
    "mobile_areas_of_strength": "Areas of Strength(Desirable Range)",
    "mobile_download_report": "DOWNLOAD REPORT",
    "norm_group": "norm group",
    "unlock_week_day_label": "Unlocks in WEEKS_REPLACEMENT and DAYS_REPLACEMENT",
    "unlock_day_label": "Unlocks in DAYS_REPLACEMENT",
    "week_label": "week",
    "weeks_label": "weeks",
    "day_label": "day",
    "days_label": "days",
    "item_expired_on_label": "Expired on",
    "item_due_on_label": "Due on",
    "desirable_range_label": "Desirable Range",
    "areas_of_improvements_label": "Areas of Improvement",
    "areas_of_growth": "Areas of Growth",
    "areas_of_strength": "Areas of Strength",
    "journey_message_from_designation_label": "Message from DESIGNATION_REPLACEMENT",
    "mobile_thank_you_for_the_feedback_label": "Thank you for the feedback",
    "mobile_view_timeline_label": "VIEW TIMELINE",
    "mobile_assessment_result_des_label": "Summary of your overall performance",
    "congratulations_label": "Congratulations",
    "congratulations_with_name_label": "Congratulations, %{name}",
    "journey_completed_greetings": "Congratulations on successfully completing the course.",
    "sim_journey_completed_greetings_label": "Congratulations on completing the course!",
    "your_report_label": "Your Report",
    "check_performance_greetings": "Now that your course is complete, check out your performance from start to finish",
    "journey_dashboard_label": "Course Dashboard",
    "mobile_share_your_score_label": "Share your score",
    "mobile_summary_of_your_overall_performance_label": "Summary of your overall performance",
    "item_finished_on_label": "Finished on",
    "hours_label": "hour(s)",
    "minutes_label": "minute(s)",
    "seconds_label": "second(s)",
    "okay_label": "Okay",
    "item_unavailable_label": "This activity is unavailable currently. You will be notified when it is unlocked.",
    "summary_not_available": "Summary will be enabled once all items are completed",
    "development_plan_label": "Personalized Development Plan",
    "development_duration_label": "Time left for you to finish",
    "development_effort_label": "Total effort it takes to complete",
    "development_prv_results_heading": "Your Performance in Assessments Track",
    "development_prv_results_desc": "We crafted a personalized development plan for you, based on your performance in the Assessments Track.",
    "assessment_results_label": "Assessment Result",
    "mt_behaviour": "Behaviour",
    "mt_competency": "Competency",
    "mt_values": "Values",
    "jit_course": "Course",
    "jit_game": "Cognitive Assessment",
    "jit_harrison_assessment": "Psychometric Assessment",
    "jit_simulation": "Simulation",
    "mobile_assessment_completed_des_label": "You’ve completed the Assessment Phase",
    "mobile_view_report_label": "VIEW REPORT",
    "go_to_home_label": "GO TO HOME",
    "contact_us_label": "CONTACT US",
    "overall_performance_label": "Your overall performance",
    "report_aboutUsLabel": "About Us",
    "report_areasOfGrowthLabel": "Areas of Growth (2.51-3.50)",
    "report_areasOfImprovementLabel": "Areas of Improvement (0-2.50)",
    "report_areasOfStrengthLabel": "Areas of Strength (3.51-5)",
    "report_arrowImagePath": "https://webassets.knolskape.com/misc/girish_kapileswarapu/2017/11/06/94/arrow.png",
    "report_assessedDate": "Assessed on",
    "report_behaviours_measured_label": "Sub Competency Assessed:",
    "report_client_behaviour_label": "Client’s Behaviours",
    "report_company2Description": "award winning solutions exceed the results of other talent management methods and personality tests by aligning people's qualifications and passions with company-specific jobs. Our unique Paradox Technology™ identifies employees and applicants who are three times more likely to achieve business results. Save up to 70% administration time with our talent acquisition and succession planning solutions. Engage and retain top talent and build high performing teams with our unique employee development and employee engagement tools.",
    "report_company2LogoPath": "https://webassets.knolskape.com/misc/deepthi_kalimili/2018/08/22/73/webp.net-resizeimage_3.jpg",
    "report_company2Name": "Harrison Assessments",
    "report_company2URL": "http://www.harrisonassessments.com/",
    "report_company1Description": "is a Modern Workplace Learning company that uses experiential technologies to accelerate learning, transform employee experience and boost productivity across four key areas: Leadership Development, Sales Effectiveness, Digital Transformation and Frontline Development. More than 200 clients in 17 countries have benefited from KNOLSKAPE's award-winning experiential solutions. Using business simulations, gamification, mobile, social, artificial intelligence, virtual reality and machine learning, KNOLSKAPE delivers transformative learning experiences for the modern learner, rich analytics for the HR teams and improved performance for the organization.",
    "report_company1LogoPath": "https://webassets.knolskape.com/misc/gana_kc/2017/11/10/98/knolskape_logo_-_copy.png",
    "report_company1Name": "KNOLSKAPE",
    "report_company1URL": "http://www.knolskape.com",
    "report_competenciesLabel": "Competencies",
    "report_competenciesMeasuredLabel": "Competencies Measured",
    "report_competencieWithHighScoresLabel": "Your High Scores",
    "report_competencieWithLowScoresLabel": "Your Low Scores",
    "report_competencyLabel": "Competency",
    "report_competencyScoresLabel": "Competency Scores",
    "report_confidentialityDocumentText": "The information in this document is confidential to the person to whom it is addressed and should not be disclosed to any other person. \nIt may not be reproduced in whole, or in part, nor may any of the information contained therein be disclosed without the prior consent of the directors of “Alight Solutions”. (‘the Company’).\nA recipient may not solicit, directly or indirectly (whether through an agent or otherwise) the participation of another institution or person without the prior approval of the directors of the Company.\nThe contents of this document have not been independently verified and they do not purport to be comprehensive, or to contain all the information that a prospective investor may need.\nNo representation, warranty or undertaking, expressed or implied is or will be made or given and no responsibility or liability is or will be accepted by the Company or by any of its directors, employees or advisors in relation to the accuracy or completeness of this document or any other written or oral information made available in connection with the Company.\nAny form of reproduction, dissemination, copying, disclosure, modification, distribution and or publication of this material is strictly prohibited.",
    "report_confidentialityDocumentTitle": "Document Confidentiality Statement",
    "report_definitionLabel": "Definition",
    "report_desirableRangeLabel": "Desirable Range",
    "report_detailedCompetencyFindingsLabel": "Detailed Competency Finding",
    "report_footerPath": "/html/accendo/footer.html",
    "report_headerPath": "/html/accendo/header.html",
    "report_hightlightsFooterLabel": "This Assessment report is purely based on your performance in the Virtual Assessment/Development Centre conducted by KNOLSKAPE and does not come with any Developmental Tips.",
    "report_instrumentsLabel": "Instruments",
    "report_introContent": "One of the most critical and an ongoing effort that a professional has to make is to develop competencies (knowledge, skills and attitude) that help not only in performing in the current role but also in building a career. In our ongoing effort to support you in developing in your current role, we are launching Development Centers. While these Centers are aimed at identifying your strengths and areas of improvement, their bigger purpose is to help you discover reflect and grow.",
    "report_introductionLabel": "Introduction",
    "report_logoPath": "https://webassets.knolskape.com/misc/vivek_duhan/2019/05/28/44/feature_temp3.png",
    "report_measured_instruments_label": "Measured with PLACEHOLDER",
    "report_overallLabel": "Overall",
    "report_poweredByLabel": "Powered by:",
    "report_reportContent": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_reportLabel": "Report Content",
    "report_reportTitle": "Virtual Assessment Report",
    "report_score1Label": "Novice",
    "report_score2Label": "Emerging",
    "report_score3Label": "Competent",
    "report_score4Label": "Proficient",
    "report_score5Label": "Role Model",
    "report_score1LabelDescription": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_score2LabelDescription": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_score3LabelDescription": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_score4LabelDescription": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_score5LabelDescription": "The reports showcase score descriptions along with your strengths and areas of improvements on competencies identified for superior performance that are imperative for your success in future.",
    "report_scoreLabel": "Score",
    "report_scoresSnapShotLabel": "Scores Snapshot",
    "report_tableOfContentsTitle": "Table of Contents",
    "report_tickImagePath": "https://webassets.knolskape.com/misc/girish_kapileswarapu/2017/11/06/1/tick.png",
    "report_toolMappingLabel": "Tool Mapping",
    "report_typeLabel": "Type",
    "report_userPlaceHolder": "Dear USER_NAME_PLACEHOLDER,",
    "report_vacScoresLabel": "Overall VAC Scores",
    "report_valueLabel": "Value",
    "report_valuesLabel": "Values",
    "report_valuesMeasuredLabel": "Values Measured",
    "report_valuesScoresLabel": "Values Scores",
    "report_your_role_label": "You are:",
    "report_your_score_label": "Your score",
    "report_reportTitleForHeader": "VAC Report",
    "novice_desc": "You are a yet to develop an ability in this competency",
    "emerging_desc": "You have the ability to develop this competency with assistance",
    "competent_desc": "You have adequate skill in this competency",
    "proficient_desc": "You role-model this competency and have the ability to mentor others to develop it",
    "role_model_desc": "You have the potential to make this competency an integral part of your organizational culture",
    "scores_label": "Scores",
    "download_certificate_label": "Download Certificate",
    "certificate_heading_desc": "Congratulations on successfully completing the course. You can download the certificate by clicking the button below",
    "certificate_comapany_logo": "https://webassets.knolskape.com/misc/vivek_duhan/2018/11/28/2/logo-main-png.png",
    "certificate_of_description": "CERTIFICATE  OF",
    "certificate_completion_text": "COMPLETION",
    "certificate_present_message_text": "PROUDLY PRESENTED TO",
    "certificate_completion_message_text": "For Successfully Completing The",
    "certificate_signature": "https://webassets.knolskape.com/misc/vivek_duhan/2018/11/29/11/signature_small.png",
    "certificate_signature_text": "RAJIV JAYARAMAN",
    "certificate_signature_designation": "CEO, KNOLSKAPE",
    "certificate_date_of_text": "DATE OF",
    "certificate_date_completion_text": "COMPLETION",
    "certificate_badge_image": "https://webassets.knolskape.com/misc/vivek_duhan/2018/11/28/28/group.png",
    "user_notfound_error_heading": "Oops!",
    "user_notfound_error_desc": "Something went wrong.",
    "user_notfound_error_sub_desc": "Sorry user is not present.",
    "support_label": "Support",
    "report_username_label": "Name",
    "report_email_label": "Email",
    "": "",
    "report_journey_status_label": "Course Completion Status",
    "course_no_modules_label": "This course has no modules",
    "no_courses_assigned_label": "No courses assigned to user",
    "course_view_more_label": "View More",
    "optional_module_items_count": "%{total_module_items} optional item(s)",
    "no_description": "No description",
    "module_no_items_label": "This module has no items",
    "module_items_completed_count_label": "%{completed_count} of %{total_count} completed",
    "course_view_less_label": "View Less",
    "module_item_topics_covered_label": "Topics Covered",
    "assignment_submission_choose_instruction_label": "To submit this assignment, please select one of the options below",
    "assignment_submission_choose_instruction_label_one_type": "Click on the option below to start with your assignment",
    "assignment_type_your_submission": "Type your submission",
    "assignment_write_here_label": "Write here...",
    "assignment_share_link_here_label": "Enter link here...",
    "assignment_additional_information_label": "Additional Information",
    "quiz_additional_information_label": "Additional Information",
    "scorm_additional_information_label": "Additional Information",
    "video_additional_information_label": "Additional Information",
    "quiz_details_label": "Quiz Details",
    "quiz_no_questions_label": "Oops... There are no questions assigned to this quiz",
    "video_speed_label": "Speed",
    "video_subtitle_label": "Subtitles",
    "video_audio_label": "Audio",
    "video_quality_label": "Quality",
    "freshdesk_support_label": "Support",
    "go_to_dashboard_label": "Go to Dashboard",
    "completed_phase_label": "Congratulations! You’ve completed all assessments",
    "report_link_label": "Report Link",
    "start_time_lock": "The ACTIVITY_TYPE has not started yet. It will start at DATE_HOLDER.",
    "end_time_lock": "The ACTIVITY_TYPE got expired at DATE_HOLDER.",
    "pre_requisite_lock": "Before moving to this ACTIVITY_TYPE , you need to complete these ACTIVITY_TYPES - ACTIVITY_NAMES",
    "phase_label": "phase",
    "batch_label": "batch",
    "item_label": "item",
    "items_label": "items",
    "phases_small_label": "phases",
    "analytics_activity_disabled_tooltip": "You need to finish atleast one activity to view this",
    "analytics_performance_disabled_tooltip": "You need to finish atleast one scoring activity to view this",
    "analytics_performance_not_available_tooltip": "This course doesn't have any performance activity",
    "journey_label": "course",
    "bg_journey_label": "course",
    "bg_start_time_lock": "The ACTIVITY_TYPE will start on DATE_PLACEHOLDER",
    "bg_end_time_lock": "The ACTIVITY_TYPE got expired at DATE_HOLDER.",
    "bg_phase_label": "phase",
    "bg_item_label": "item",
    "bg_pre_requisite_lock": "Before moving to this ACTIVITY_TYPE , you need to complete these ACTIVITY_TYPES - ACTIVITY_NAMES",
    "bg_phases_small_label": "phases",
    "bg_items_label": "items",
    "bg_batch_notstarted": "This course will start on DATE_PLACEHOLDER",
    "bg_batch_expired": "This course got expired on DATE_PLACEHOLDER",
    "bg_batchphase_notstarted": "The phase will unlock on DATE_PLACEHOLDER",
    "bg_batchphase_prerequisite": "The phase unlocks on completing ACTIVITY_NAMES.",
    "bg_batchphase_expired": "The phase got expired on DATE_PLACEHOLDER",
    "bg_batchitem_notstarted": "Unlocks on DATE_PLACEHOLDER.",
    "bg_batchitem_prerequisite": "Unlocks on completing ACTIVITY_NAMES.",
    "bg_batchitem_expired": "Expired on DATE_PLACEHOLDER.",
    "bg_batch_notstarted_but_user_completed": "This course is locked.",
    "bg_batch_expired_but_user_completed": "This course has ended.",
    "bg_batchphase_notstarted_but_user_completed": "This phase is locked.",
    "bg_batchphase_expired_but_user_completed": "This phase has ended.",
    "bg_batchitem_notstarted_but_user_completed": "The item is locked",
    "bg_batchitem_expired_but_user_completed": "The item has ended",
    "reset_journey_label": "Reset Course",
    "like_to_hear_from_you_label": "We’d like to hear from you",
    "section_tile_index": "Section %{index}",
    "tooltip_next_activity_label": "Next Activity",
    "feedback_please_complete_phase_label": "Please complete all the items",
    "exit_confirmation_yes_label": "YES, EXIT",
    "course_completion_note": "You've completed %{course_name}",
    "phase_completion_note": "You've completed %{phase_name} Phase",
    "thank_you_note_after_feedback": "Thanks for your feedback",
    "phase_feedback_sub_header": "Your feedback helps us make better courses",
    "course_feedback_sub_header": "Your feedback helps us make better courses",
    "phase_feedback_note": "* represents mandatory questions. To submit feedback, please answer all mandatory questions.",
    "course_feedback_header": "Feedback for %{phaseItemName}",
    "phase_feedback_header": "Feedback for %{phase_name} Phase",
    "tell_us_more_label": "Tell us more...",
    "question_number_label": "Question %{number}",
    "feedback_submit_label": "Submit",
    "feedback_skip_label": "Skip",
    "feedback_edit_responses_label": "Edit responses",
    "go_to_measures_label": "Go to measures",
    "open_new_tab_label": "Open In New Tab",
    "back_to_home_label": "Back To Home",
    "feedback_item_type": "Feedback",
    "save_feedback_responses": "Save Responses",
    "max_rating_label": "Very Likely",
    "min_rating_label": "Not Likely",
    "document_page_of_total_label": "%{current_page} of %{total_pages}",
    "resume_simulation_label": "RESUME SIMULATION",
    "assignment_your_submission_heading_label": "Your submission",
    "assignment_submitted_on_label": "Submitted on",
    "assignment_evaluation_pending_label": "Evaluation Pending",
    "assignment_evaluation_complete_label": "Evaluation Complete",
    "resubmit_assignment_label": "Re-submit",
    "content_label": "Content",
    "assignment_upload_file_label": "Upload file(s)",
    "drag_and_drop_label": "Drag and drop or",
    "assignment_browse_file_label": "Browse",
    "assignment_file_uploading_label": "Uploading...",
    "assignment_resubmit_heading_label": "You can re-submit your assignment to replace the existing response",
    "quiz_resume_message_label": "We see you did not submit your last quiz attempt. Please resume.",
    "quiz_unlimited_attempts_label": "Unlimited Attempts",
    "quiz_resume_label": "Resume Quiz",
    "quiz_questions_answered_count_label": "%{answered_count}/%{total_count} answered",
    "quiz_questions_points_possible_label": "%{points} PTS",
    "quiz_reset_answer_label": "Reset Answer",
    "submit_label": "Submit",
    "quiz_exit_confirmation_message": "Are you sure you want to exit the quiz?",
    "quiz_submit_confirmation_unanswered_label": "<span>You have not answered <b>%{unanswered_questions_count}</b> questions. Are you sure you want to submit the quiz?</span>",
    "quiz_submit_confirmation_label": "Are you sure you want to submit the quiz?",
    "quiz_submission_in_progress_label": "Please Wait. Submitting your answers.",
    "quiz_review_answers_label": "REVIEW ANSWERS",
    "quiz_kept_score_highest": "Highest Score",
    "quiz_kept_score_latest": "Latest Score",
    "quiz_kept_score_average": "Average Score",
    "quiz_your_attempts_label": "Your Attempts",
    "quiz_your_last_attempt_label": "Your Last Attempt",
    "quiz_your_last_attempts_label": "Your Last NUMBER_OF_ATTEMPTS Attempts",
    "quiz_attempt_number_label": "Attempt %{attempt_number}",
    "quiz_result_for_label": "RESULT FOR",
    "quiz_chart_right_answers": "Right answered questions",
    "quiz_chart_wrong_answers": "Wrong/partially answered questions",
    "quiz_chart_unanswered_questions": "Unanswered questions",
    "quiz_review_attempt_score_heading": "Attempt %{attempt_number} score",
    "image_label": "Image",
    "read_more_text_label": "Read More",
    "quiz_loading_label": "Loading Quiz Content...",
    "quiz_attempt_only_once_review_disabled_label": "You can review your responses only once for each attempt of this quiz.",
    "quiz_attempt_only_after_last_review_disabled_label": "You can review your responses only after last %{attempts_count} attempt.",
    "completion_note": "You have completed %{entity_name}",
    "assignment_submitted_label": "Your assignment has been submitted successfully.",
    "resume_content_label": "Resume",
    "quiz_score_results_label": "RESULTS",
    "quiz_submit_confirmation_yes_label": "Yes, submit",
    "quiz_flag_this_question_label": "Flag this question",
    "phase_no_activities_label": "There are no activities in this phase, yet.",
    "max_score": 5,
    "noDueDate": "",
    "attempts_left_label": "%{count} attempts left",
    "submitting_with_all_answers": "Are you sure you want to Submit?",
    "scoreMidLabel": "Areas of Growth (Desirable Range)",
    "mobile_areas_of_improvement__1": "Areas of Improvement",
    "assignmentSubmissionSuccess": "Assignment submitted successfully",
    "bestScoreLabel": "Best Score",
    "fdbk_detailed_cancel": "Cancel",
    "quizdialogNegative": "Cancel",
    "quizReviewDisabledTillLastAttempt": "Complete All Attempts to see Results",
    "pendingItemsMsg": "Complete the pending items to proceed",
    "certificateEarnedDescription": "Congratulations on successfully completing the course. You can download the certificate by clicking the button below",
    "quizCorrect": "Correct",
    "datePlaceHolder": "DATE_PLACEHOLDER",
    "invalidUrlError": "Enter a valid URL!",
    "errorLabel": "Error",
    "feedbackLabel": "Feedback",
    "fdbk_title": "Feedback for %{entityName}",
    "quizRetakeText": "Give it another try?  You have %{count} more attempts.",
    "userHiLabel": "Hi",
    "quizIncorrect": "Incorrect",
    "itemExpiredLabel": "Item Expired",
    "itemLockedLabel": "Item Locked",
    "itemsPendingLabel": "item(s) pending",
    "quizNotAnswered": "Not Answered",
    "mobile_not_supported": "Not Supported in Mobile",
    "ofLabel": "of",
    "assgnUploadMsgFormats": "Other file formats can be uploaded only on web",
    "quizPartiallyCorrect": "Partially Correct",
    "pendingLabel": "Pending",
    "submitAssignmentDescription": "Please choose one of the following options to submit your assignment :",
    "profile_summary": "Profile Summary",
    "pointsShortLabel": "PTS",
    "pointsShortLabelSmall": "pts",
    "quizReviewDisabled": "Quiz Review Disabled",
    "feedbackRateExperience": "Rate your experience!",
    "fdbk_detailed_resubmit": "Re-Submit",
    "assignmentResubmitButtonText": "RE-SUBMIT",
    "retryLabel": "Retry",
    "start_quiz": "START QUIZ",
    "quizdialogPositive": "Submit",
    "submitAssignmentLabel": "Submit Assignment",
    "submit_link": "Submit Link",
    "submittingFdbk": "Submitting Feedback...",
    "assgnUploadMsg": "Tap to upload an image or video",
    "notSupportedExplaination": "This {%type} requires a larger screen for an optimal experience. Please open it in web to complete this item",
    "notSupportedText": "This item is not optimised for mobile phones",
    "topCompetenciesTitle": "Top Competencies",
    "submittingAfterTimeup": "Uh oh! You ran out of time. Your answers will be auto submitted.",
    "assgnUploadMoreMsg": "Upload more files",
    "assignmentResubmissionText": "You can resubmit your assignment to overwrite the existing one",
    "certificateEarnedLabel": "You earned a Certificate",
    "quizUnlimitedAttempts": "You have Unlimited Attempts",
    "submittingWithoutAllAnswers": "You haven’t answered {questions} question(s). Are you sure you want to Submit?",
    "course_completion_msg": "You've completed {courseName}",
    "itemNotCompletedText": "You’ve %{count} item(s) remaining in this %{context}",
    "youHaveCompletedLabel": "You’ve completed {%itemName}",
    "fdbk_a_completed": "You’ve completed {phaseName}",
    "assignmentSubmittedWithTime": "You’ve submitted this assignment %{time} ago",
    "allAttemptsUsed": "You’ve used up all %{count} attempts",
    "quizAverageScoreConsidered": "Your average score will be considered",
    "quizBestScoreConsidered": "Your best score will be considered",
    "yourFeedbackLabel": "Your Feedback",
    "quizLatestScoreConsidered": "Your latest score will be considered",
    "assignmentEvaluated": "Your submission has been evaluated",
    "courseLabel": "course",
    "certificateLabel": "Certificate",
    "previewCertificate": "Open Certificate",
    "submit_quiz": "Submit Quiz",
    "mobile_fdbk_item_edit": "Edit",
    "loadingFailed": "Loading Failed, Try Again",
    "measuredWithLabel": "MEASURED WITH",
    "nextActivityLabel": "Next Activity",
    "willBeHereLabel": "Will Be here",
    "comingSoonLabel": "Coming Soon",
    "youLabel": "You",
    "itemsCompletedLabel": "Item(s) Completed",
    "helperVideoLabel": "Helper Video",
    "hideMoreLabel": "Hide More",
    "submittingLabel": "Submitting...",
    "assignmentUploadNoFileErrorLabel": "Upload at least 1 file",
    "assignmentUploadFailed": "Upload failed, please retry",
    "assignmentFileDeleteLabel": "Delete {file_name}",
    "areYouSureConfirmation": "Are you sure?",
    "assignmentEmptySubmission": "Your submission is empty",
    "openingLabel": "Opening...",
    "openFileLabel": "Open {file_type}",
    "submitQuizLabel": "Submit Quiz",
    "submittingQuizLabel": "Submitting Quiz!",
    "quizTimeUpTitle": "Uh Oh! Time's up",
    "feedbackDurationTimelabel": "2 mins",
    "phaseGetStartedSingleItemLabel": "You’ve {total_items} Item. Let's Get started",
    "phaseGetStartedLabel": "You’ve {total_items} Items. Get started with the first one",
    "appNameTitle": "Aktivlearn Plus",
    "unknownView": "Unknown View",
    "tcURL": "https://accounts.knolskape.com/user/terms-and-conditions?service=lms&variant=default&type=1&continue=https%3A%2F%2Faccounts.knolskape.com%2Fv1%2Foauth%2Fauthorize%3Fservice%3Dlms%26type%3D1%26demostorylineid%3D1%26client_id%3Dlmsclient%26redirect_uri%3Dhttps%3A%2F%2Fkonsole.knolskape.com%3F%26scope%3Dopenid+offline_access%26state%3DeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhZnRlclVybCI6Imh0dHBzOlwvXC9rb25zb2xlLmtub2xza2FwZS5jb20_bm9jYWNoZT0xMTMzMDg4ODUzMTU0MzM4ODAxNCJ9.3Ba0IEiUkph-8danoi7lLEzQOwOswITOcVlCGNTuXc8%26response_type%3Dcode%26variant%3Ddefault%26variant%3Ddefault&demostorylineid=1",
    "noLabel": "NO",
    "yesLabel": "YES",
    "previous_label": "Previous",
    "upload_file_label": "Upload File",
    "writeup_label": "Write Up",
    "downloaded_label": "Downloaded",
    "download_label": "Download",
    "downloading_label": "Downloading",
    "switch_label": "SWITCH",
    "quiz_unlimit_retake": "Give it another try?  You have unlimited attempts.",
    "quiz_no_more_attemts": "You have no more attempts.",
    "optional_label": "Optional",
    "starts_on_label": "Starts on",
    "ends_on_label": "Ends on",
    "modules_completed_label": "Modules completed",
    "type_something_label": "Type something…",
    "please_write_something_label": "Please write something",
    "ok_label": "OK",
    "files_are_uploading_label": "Files are uploading",
    "files_are_uploading_progress_label": "%{completed_count}/%{total_count} uploaded",
    "web_product_logo": "",
    "certificate_congrats_message": " Congratulations on successfully completing the course. You can now download or share your certificate.",
    "reset_journey_confirmation_message": "Are you sure you want to reset data for this course?",
    "reset_journey_yes_label": "Yes, reset",
    "reset_journey_cancel_label": "No",
    "course_feedback_label": "Course Feedback",
    "phase_feedback_label": "%{phase_name} Phase Feedback",
    "download_file_label": "Download the file",
    "replay_content_label": "Re-play",
    "replay_simulation_label": "Re-play Simulation",
    "no_notifications_label": "No notifications yet...",
    "welcome_user_note": "Welcome, USER_NAME",
    "active_journeys_label": "Active Courses",
    "courses_to_go_label": "Courses to go",
    "all_courses_are_completed": "All courses are completed",
    "upcoming_journeys_label": "Upcoming",
    "completed_journeys_label": "Completed",
    "certificates_recieved_label": "Certificates Received",
    "simulations_played_label": "Simulations Played",
    "workshops_attended_label": "Workshops Attended",
    "journey_catalogue_label": "Explore Courses",
    "continue_learning_label": "CONTINUE LEARNING",
    "journey_completion_label": "COMPLETION_PERCENTAGE% completed",
    "your_activity_label": "Your Activity",
    "view_all_label": "View All",
    "usage_stats_label": "Usage Stats",
    "total_time_spent_label": "Total time spent",
    "first_access_label": "First Access",
    "recent_access_label": "Recent Access",
    "time_spent_across_journey_label": "Time Spent across courses",
    "request_access_label": "REQUEST ACCESS",
    "i_m_interested_label": "I'M INTERESTED",
    "interested_label": "INTERESTED",
    "cancel_interest_label": "Cancel Interest",
    "re_request_access_label": "Request Again",
    "already_requested_msg": "Your request has been sent to the admin. You will be notified once it is approved",
    "already_requested_label": "Access Requested",
    "request_rejected_msg": "Your request for this course has been rejected by the admin.",
    "request_rejected_label": "Request Rejected",
    "request_accepted_msg": "You are already part of this course.",
    "request_accepted_label": "REQUEST ACCEPTED",
    "sign_in_label": "Sign In",
    "sign_in_to_request_label": "Sign in to request",
    "sign_up_label": "Sign up",
    "sign_up_to_request_label": "Sign up to request",
    "my_journeys_label": "My Courses",
    "approval_rejected_label": "Admin cancelled your request",
    "pending_approval_label": "Approval from Admin pending",
    "no_journeys_label": "No Courses",
    "no_journeys_found_label": "No Courses Found",
    "no_categories_label": "No categories found",
    "enroll_for_new_journey_label": "ENROLL IN ANOTHER COURSE",
    "requested_journeys_label": "Requested",
    "expired_journeys_label": "Expired Courses",
    "journeys_label": "Courses",
    "start_learning_label": "START LEARNING",
    "about_label": "ABOUT",
    "cancel_request_label": "Cancel Request",
    "view_journey_label": "VIEW COURSE",
    "logout_confirmation_message": "Are you sure you want to sign out?",
    "request_accepted_auto_enroll_label": "You have successfully enrolled in this course.",
    "enrol_label": "ENROLL",
    "sign_in_to_enrol_label": "Sign in to Enroll",
    "sign_up_to_enrol_label": "Sign up to Enroll",
    "enrolled_successfully_label": "Enrolled Successfully",
    "profile_label": "My Profile",
    "save_label": "Save",
    "basic_info_label": "Basic Info",
    "first_name_label": "First Name",
    "last_name_label": "Last Name",
    "title_label": "Title",
    "mr_title_label": "Mr.",
    "mrs_title_label": "Mrs.",
    "ms_title_label": "Ms.",
    "old_password_label": "Current Password",
    "new_password_label": "New Password",
    "confirm_password_label": "Enter your password again",
    "change_password_label": "Change Password",
    "password_dont_match_label": "Passwords don't match",
    "catalog_sign_up_to_request_label": "Signup now to request access to courses",
    "signup_now_label": "Signup now",
    "wrong_org_code_entered_label": "Looks like you have entered wrong organization code. Please contact admin.",
    "updating_password_label": "Updating Password...",
    "password_rules_label": "Password Rules:",
    "password_rule_label_1_label": "Should have atleast one capital letter, a small letter, a number, a special character: @$!%*?& and minimum of 10 characters.",
    "password_rule_label_2_label": "Should not be same as existing password and 3 previous ones.",
    "password_rule_label_3_label": "Should not contain username / first name / last name.",
    "password_example_label": "Example: aBcde@1234",
    "edit_label": "Edit",
    "phone_number_label": "Phone Number",
    "loading_simulation_label": "Loading Simulation...",
    "loading_content_label": "Loading Content...",
    "assignment_submitting_label": "Please wait. Submitting your assignment...",
    "other_info_label": "Other Info",
    "select_option_label": "Select your option",
    "save_feedback_responses": "Save Feedback",
    "give_feedback_label": "Give Feedback",
    "view_feedback_responses_label": "View Feedback Responses",
    "feedback_title": "Feedback for %{entityName}",
    "done_toast_msg": "Done!",
    "profile_changes_saved_success_toast_msg": "Profile changes saved successfully!",
    "profile_changes_saved_error_toast_msg": "Sorry, unable to update profile details!",
    "accepted_invitation_success_toast_msg_heading": "Successfully Accepted Invitation",
    "accepted_invitation_success_toast_msg": "Your request to accept invitation has been submitted",
    "accepted_invitation_error_toast_msg": "Your request to accept invitation has been failed. Kindly retry!",
    "something_went_wrong_toast_msg": "Something went wrong!",
    "input_submitted_success_toast_msg": "Your inputs have been submitted.",
    "input_submitted_error_toast_msg": "There was an error while submitting your inputs.",
    "password_updated_success_toast_msg": "Your password has been updated.",
    "password_updated_error_toast_msg": "Sorry, unable to update your password!",
    "success_label": "Success",
    "designation_label": "Designation",
    "city_label": "City",
    "dob_label": "Date of birth",
    "go_to_journey_label": "Go to course",
    "downloads_label": "Downloads",
    "no_completed_journeys": "Please complete a course to download your first certificate here.",
    "certificates_label": "Certificates",
    "knolskape_faqs_label": "FAQs",
    "share_on_liked_in_label": "Share on LinkedIn",
    "please_wait_label": "Please wait...",
    "new_window_blocked_message": "Oops! Looks like popup content is blocked. Please add this site to your exception list.",
    "quiz_attempt_exhausted_label": "Your last attempt have been submitted and there are no more attempts left for this quiz. Redirecting you back to details page...",
    "system_check_label": "System Check",
    "subscribe_notification_label": "Subscribe notifications",
    "subscribe_notification_option_updated_label": "Changes saved successfully",
    "subscribe_notification_option_error_label": "Error while saving the changes.",
    "quiz_question_points_scored_label": "Points Scored: POINTS_SCORED/MAX_POINTS PTS",
    "organizations_list_label": "My Organization(s)",
    "set_active_organization_label": "Set Active",
    "active_organization_label": "Currently active",
    "web_session_join_link_label": "JOIN LIVE STREAM",
    "web_session_date_time_heading_label": "DATE & TIME",
    "web_session_resources_label": "Resources",
    "web_session_facilitators_label": "Facilitator(s)",
    "search_journeys_placeholder_label": "Search",

    "mandatory_give_feedback_button_label": "Give Feedback",
    "mandatory_feedback_button_sidetext_label": "Please share your feedback to get the certificate",
    "certificate_heading_label": "You earned a certificate!",
    "certificate_congrats_message_feedback_incomplete": "Congratulations on successfully completing the course. There's one last step before you can download your certificate.",
    "feedback_list_add_feedback_button_label": "ADD FEEDBACK",
    "feedback_list_edit_feedback_button_label": "Edit Feedback",
    "assessment_phase_feedback_label": "Assessment Phase Feedback",
    "optional_course_modules_count": "%{total_optional_course_modules} optional section(s)",
    "journey_categories_label": "Categories",
    "journey_formats_label": "Formats",
    "uncategorized_journeys_heading_label": "ADDITIONAL COURSES",
    "filters_all_selected_label": "All Selected",
    "feedback_list_total_items_heading_label": "TOTAL ITEMS",
    "feedback_list_items_feedback_pending_label": "Feedback Pending",
    "presentation_view_label": "Presentation View",
    "checking_system_message_label": "Checking your system for a seamless learning experience",
    "system_check_complete_message_label": "Congrats! Your system is now ready.",
    "loading_with_ellipsis_label": "Loading...",
    "page_complete_label": "Complete",
    "page_mark_complete_label": "Mark Complete",
    "quiz_your_score_label": "Your Score",
    "format_SELF_PACED_label": "Self paced",
    "format_ILT_label": "ILT",
    "format_VLT_label": "VILT",
    "format_BLENDED_label": "Blended",
    "COMPLETED_label_styleA": "COMPLETED",
    "category_DIGITAL_CULTURE_label": "Digital culture",
    "category_DIGITAL_FUNDAMENTALS_label": "Digital fundamentals",
    "category_DIGITAL_LEADERSHIP_AND_MINDSETS_label": "Digital leadership and mindsets",
    "category_DIGITAL_STRATEGY_AND_INNOVATION_label": "Digital strategy and innovation",
    "category_LEADING_BUSINESS_label": "Leading business",
    "category_LEADING_SELF_label": "Leading self",
    "category_LEADING_TEAMS_label": "Leading teams",
    "category_LEADING_DIGITAL_EXECUTION_label": "Leading digital execution",
    "category_LEADING_DIGITAL_TRANSFORMATION_label": "Leading digital transformation",
    "category_LEADING_IN_THE_DIGITAL_WORLD_label": "Leading in the digital world",
    "domain_LEADING_NOW_label": "Leading NOW",
    "domain_LEADING_NEXT_label": "Leading NEXT",
    "tpc_warning_heading_label": "Third Party Cookies Are Disabled",
    "tpc_warning_description_label": "You might face issues accessing some content in your browser.",
    "tpc_warning_resolution_label": "<span><a href=\"https://support.panopto.com/s/article/How-to-Enable-Third-Party-Cookies-in-Supported-Browsers\" target=\"_blank\">Here's how to enable them</a>. For Mac, iPhone or iPad refer to <a target=\"_blank\" href=\"https://webassets.knolskape.com/misc/amul_garg/2023/08/01/83/thirdpartycookiesios.png\" rel=\"noopener noreferrer\">this</a>.</span>",
    "tpc_check_warning_heading_label": "Third Party Cookies Check Failed",
    "tpc_check_warning_description_label": "We were unable to check if third party cookies are enabled in your browser.",
    "system_check_resolution_what_can_you_do": "What can you do to fix this issue?",
    "system_check_result_why_label": "Why?",
    "more_details_label": "More Details",
    "system_check_continue_warning_label": "<div style='text-align: left'>Looks like there are some system configuration errors which might have an impact on your overall experience on the platform. Do you still wish to continue? <div style='margin-top: 10px;'><b>Note:</b> You can run the system check again as and when needed by clicking the \"System Check\" button in the profile dropdown menu</div></div>",
    "whitelisting_solution_label": "Ask your network admin to whitelist the above domains and sub-domains. <br/><br/> <b> Note: </b> Some URLs like api.mixpanel.com and api-js.mixpanel.com can be inaccessible also because of strict privacy settings in Mozilla Firefox. Try disabling <a href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop#w_what-to-do-if-a-site-seems-broken' target='_blank'>Enhanced Tracking Protection </a> and run this check again. Also, please disable ad-blockers on this website.",
    "category_others_heading_label": "Unable to load some URLs",
    "category_others_description_label": "Aktivlearn Plus requires access to the following domains to provide you with a stellar experience. Access to few links and files might be broken to this.",
    "requirements_label": "Requirements",
    "adblocker_warning_heading_label": "Ad-blocking Tool Detected",
    "adblocker_warning_description_label": "Ad-blocking tools interfere with some features of the application. We don't show any ads, so you can safely disable the ad-blocker for this website.",
    "os_warning_heading_label": "Incompatible OS",
    "os_warning_description_label": "Incompatible OS",
    "os_warning_supported_os_label": "Supported Operating Systems: %{OS_NAMES}",
    "browser_warning_heading_label": "Incompatible Browser",
    "browser_warning_description_label": "AktivLearn Plus is incompatible with the browser you\'re using. It might lead to a broken experience",
    "browser_warning_supported_browsers_label": "Supported browsers: %{BROWSER_NAMES}",
    "screen_resolution_warning_heading_label": "Unsupported Screen Resolution",
    "screen_resolution_warning_description_label": "This screen resolution might lead to a broken experience",
    "screen_resolution_warning_supported_resolution_label": "Minimum Supported Resolution: %{RESOLUTION}",
    "websession_dialog_upcoming_label": "Upcoming web session for",
    "websession_dialog_started_label": "Web session has started for",
    "websession_join_label_big": "JOIN SESSION",
    "journey_card_session_in_progress_label": "Online session in progress",
    "journey_info_web_session_upcoming_label": "Upcoming Web Session",
    "journey_info_web_session_other_sessions_label": "Other Sessions",
    "journey_banner_active_session_in_progress_label": "Online session in progress",
    "journey_upcoming_sessions_modal_heading": "Active / Upcoming Sessions",
    "websession_dialog_starts_label": "Starts",
    "websession_dialog_ends_label": "Ends",
    "websession_led_by_label": "Led by",
    "assessment_loading_after_exit_label": "This assessment is currently in progress",
    "harrison_assessments_label": "Psychometric Assessment",
    "talent_pulse_assessment_label": "TalentPulse Assessment",
    "file_label": "File",
    "course_label": "Course",
    "leadership_bundle_label": "Leaders",
    "managers_bundle_label": "Managers",
    "ic_bundle_label": "IC",
    "linkedin_certificate_update_label": "Your certificate has been shared on your Linkedin Account!",
    "linkedin_click_to_see_label": "Click here to see it",
    "linkedin_certificate_post_fail_label": "Unable to post to Linkedin. Please contact support.",
    "autoenroll-enabled-msg-label": "Aiming to expand your skill set? Explore our comprehensive catalog, identify your next learning goals, and embrace the benefits of experiential learning across diverse skill areas.",
    "interest-enabled-msg-label": "Discover the wide array of experiential learning courses and let us know which ones interest you.",
    "category_COURSES_label": "COURSES",
    "category_JOURNEYS_label": "JOURNEYS",
    "category_BUNDLES_label": "BUNDLES",
    "not_enrolled_in_any_journey_msg": "You are not enrolled into any course. Please reach out to our Support Team",
    "freshchat_label": "Chat"
});

export default defaultLabels;
